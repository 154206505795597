import React, { useState, useEffect,useRef } from 'react'
import Axios from 'axios'
import {navigate} from 'gatsby'
import { initAxiosInterceptors } from 'utils/axiosConfig'
import { useDialog } from 'context/DialogContext'
import { useLoading } from 'context/LoadingContext'
import GridContainer from "components/material-dashboard-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-dashboard-pro-react/components/Grid/GridItem.js"
import PdfViewer from 'Portal/Views/Digitization/PdfViewer'
import LandingPage from 'LandingPageMaterial/Layout/LandingPage'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { RViewer, RViewerTrigger } from "react-viewerjs"
import Button from "components/material-kit-pro-react/components/CustomButtons/Button"

import queryString from 'query-string'

export default function DocumentView(props) {
    const params_url = queryString.parse(props.location.search)
    const dialog = useDialog();
    const loading = useLoading();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [msgError, setMsgError] = useState(null)
    const [showImage, setShowImage] = useState(false)

    const contentTypePdf = "application/pdf"
    const contentTypeImage = ["image/png","image/jpeg","image/jpg"]
    const contentTypeAccepted = useRef()

    const [url, seturl] = useState(null) 
    const [fileOrigin,setFileOrigin] = useState(null)

    const initialcontentTypeAccepted = () => {      
        contentTypeAccepted.current = [contentTypePdf,...contentTypeImage]      
    }
  

    async function getDocuments(hash) {
        const params = { p_hash: hash }
        try {
          const response = await Axios.post('/dbo/documents/get_document_by_hash', params)
          if (response.data.p_doc_params.p_doc_params) {
            //JSON.parse('{"p_doc_params" : {"p_json_params" : {"p_policy_id" : 1817877, "p_certificate_id" : 1, "p_document_type" : "F022", "p_expiration_date" : "24/09/2024 16:31:32", "content_type" : "application/pdf"    },  "p_params_config" : {   "nameServiceSettings" : "general_policies",  "servicePath" : "get_document_suscription",  "cursorName" : "result", "blobName" : "BLOB_F"    }}}')
            /*
              DECLARE 
              v_verification_json_string varchar2(10000);
              BEGIN
                
              v_verification_json_string  	:= acsel.pr_utilidades_documentos.fb_descencriptar('477B439E991E8B9A255E09CE8C498587E705FD062CF43628E2B8A29738CC47F3AED838C1FFCB439B159529250B1BD8F7A5B8908A962BC7EF2D414CBC5F99CC98A5329FEC9AB95C71072A41816529103F6DA8C83CF62FD0D054FDA6564525F2DF76DC2EA253A51953CD864BC4C7F9C871EE2F6891EB36A10E3748C49C1F6005FA92EC72E8B28AC649FC78CA9C671D49F147F752F58FF61206288CBF17DD94BD7E0EE2A088E0E7B0264337D63FC3564AFC7E79F8EE5E3206E53EBC64310F431F02B60D59AB8E395348EC412CD99EAE5261BD710CFF5E197A5CF322C6EAE94DE2A10599B9B3C679FA075B194FD6E5EEB740C5B6A563F25118DF6B53C3EC0721EA62038F9F05E07704E4535C559A051914E1316E3AC7B0E5951474EE07908F17A3332B9740ED6FCDE23876F262DCA5E9CA66C84E3E68A432122566DDDAA7B9F50EB3052A8F9C1347977B03DA91E34C22E1A6E9E8684AEE7B34A3FB42E57578B2F6402E90D9ED84138DDEBFC96F97762563DC7182278DD1E86CF141E80B008A81ECA4A4490C0262E6FD60DA036AEE9C0D2619D73A8D8E3E973F3F56C9A406603E1CB9C4BA7FA2046762B749F3CE952F322CC82B40213B92688B8BDF70219354A52A47567893E89B83244C');
              dbms_output.put_line(v_verification_json_string);
              END;
            */
           // Con el código comentado se desencripta el hash para probar localmente RL.
            handleSelectList(response.data.p_doc_params)
          }
        } catch (e) {
          console.log("Error: ", e)
          navigate('/')
        }
      }

    async function handleSelectList(reg) {
      let url = '/dbo/workflow_inbox/get_document_blob/get_blob/p_cur_doc/BLOB_FILE'
      let params = ''
      let paramsConfig = ''
      let type_data = ''

      if (typeof (reg.p_doc_params.p_json_params) !== 'undefined') {
        paramsConfig = reg.p_doc_params.p_params_config
        params = { p_json_params: JSON.stringify(reg.p_doc_params.p_json_params), responseContentType: "application/pdf" }
        url = '/dbo/'+paramsConfig.nameServiceSettings+'/'+paramsConfig.servicePath+'/get_blob/'+paramsConfig.cursorName+'/'+paramsConfig.blobName
        type_data = reg.p_doc_params.p_json_params
      } else {
        params = { p_doc_values: JSON.stringify(reg.p_doc_params.p_doc_values), responseContentType: "application/pdf" }
        type_data = reg.p_doc_params.p_doc_values;
      }
      const response = await Axios.post(url, params, {
          responseType: 'arraybuffer',
          responseEncoding: 'binary'
      })
      toBlob(response.data, response.headers['content-type'])
    }

    function toBlob(buffer, contentType) {

        const file = new Blob([buffer], { type: contentType})
        const fileURL = URL.createObjectURL(file)
        
        if (contentTypeImage.includes(contentType)){
          setShowImage(true)
          seturl(fileURL)
          setFileOrigin(file)
        }
        else{
          setShowImage(false)
          setPdfUrl(null)
          setMsgError(null)
          setPdfUrl(fileURL)             
        }  

        
    }

    async function downloadBlob(file) {
      try {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
    
        const extension = file.type.split('/').pop();
    
        a.href = window.URL.createObjectURL(file);
        a.download = `archivo.${extension}`;
    
        a.click();
        window.URL.revokeObjectURL(a.href);
      } catch (error) {
        console.error(error);
      }
    }

    useEffect(() => {
        initAxiosInterceptors(dialog,loading)
        initialcontentTypeAccepted()
        getDocuments(params_url.id)
    }, [])

    return (
      <>
        <LandingPage noLinks noRrss noFooter noChatBot noDial>
          {!showImage ? 
            <GridContainer style={{minHeight:'80vh', backgroundColor: '#a9a9a9'}}>
                <GridItem xs={12} style={{ marginTop:'75px' }}>
                    { pdfUrl && <PdfViewer pdfUrl={pdfUrl}/>}
                </GridItem>
            </GridContainer>
            :

            <Dialog open={showImage}>
                <DialogContent>
                        <div style={{justifyContent:'center', textAlign:'center'}}>
                           <BaseComponentPreview url={url}/>
                        </div>
                        <div style={{justifyContent:'center', textAlign:'center'}}>
                          <Button color="warning" size={"sm"} onClick={() => downloadBlob(fileOrigin)}>
                            Descargar
                          </Button>
                        </div>                        
                </DialogContent>
              </Dialog> 
          }
        </LandingPage>
      </>
    )
}


const ImagePreview = (url) => {
  const buttonRef = useRef(null);
  let sourceUrl = url['url'].url;
  let options={
    toolbar: {
      prev: false,
      next: false
    }
  }  

  useEffect(() => {
    // Simular un clic en el botón después de un cierto tiempo (ajusta el tiempo según sea necesario)
    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.click();
      }
    }, 200); // Cambia 1000 por el número de milisegundos que desees esperar
  }, [buttonRef]);

  return (
    <>
      {url  && (
        
        <RViewer options={options} imageUrls={[sourceUrl]}>
          <RViewerTrigger >
            <button ref={buttonRef} style={{
                    backgroundColor: '#ff9800',
                    fontFamily:'Museo Sans',
                    fontWeight: '500',
                    fontSize:'0.6875rem',
                    WebkitTapHighlightColor:'transparent',
                    color: '#fff',
                    padding: '0.40625rem 1.20rem',
                    borderRadius: '0.2rem',
                    border:'none',
                    boxShadow: '0 2px 2px 0 rgba(252, 45, 34, 0.14),0 3px 1px -2px rgba(252, 45, 34, 0.2),0 1px 5px 0 rgba(252, 45, 34, 0.12)',
                    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),background-color 0.2s cubic-bezier(0, 0, 0, 19)',
                    textTransform: 'uppercase',
                    margin: '.3125rem 1px',
                    position:'relative',
                    lineHeight: '1.55',
                    minWidth:'auto',
                    minHeight:'auto',
                  }}>Ver Imagen</button>
          </RViewerTrigger>
        </RViewer>
      
      )}  
    </>
  )
}         

const BaseComponentPreview = (url) => {
  
  return (
    <div>
      <ImagePreview url={url}/>
    </div>
  )
};